import React from 'react';
import { clickCopy } from '../services/CopyService';

const SharePage = ({url}) => (
	<>
		<div className="jumbotron text-center" id="jumbo">
			<section>
				<div className="page-header" id="page-header">
					<h3>KOCHpass</h3>
					<p>A simple app to share secrets</p>
				</div>
			</section>
		</div>
		<div className="input-group">
			<span className="input-group-addon" id="basic-addon1"><span className="glyphicon glyphicon-globe" aria-hidden="true"></span></span>
			<input type="input" className="form-control" id="password-link" value={url || "URL not available, please try again"} readOnly />
			<span className="input-group-btn">
				<button onClick={() => clickCopy(url)} title="Copy to clipboard" className="btn btn-primary"><i className="fa fa-clipboard"></i> Copy</button>
			</span>
		</div>
		<p>Your secret has been temporarily saved. Copy and send the following URL to your intended recipient.</p>
	</>
);

export default SharePage;


