import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { decodeSecret } from "../services/SecretService";
import { clickCopy } from "../services/CopyService";

const SecretPage = ({ toggleSpinner }) => {
  const { uid, key } = useParams();
  const [secret, setSecret] = useState("");
  const [error, setError] = useState(false);

  const getSecret = async () => {
    toggleSpinner(true);
    const response = await decodeSecret(uid, key);
    const jsonResponse = await response.json();
    setError(!response.ok);
    toggleSpinner(false);
    setSecret(jsonResponse.secret);
  };

  const getSecretCallback = useCallback(getSecret, [key, toggleSpinner, uid]);

  useEffect(() => getSecretCallback(), [getSecretCallback]);

  return (
    <>
      <div className="jumbotron text-center" id="jumbo">
        <section>
          <div className="page-header" id="page-header">
            <h3>KOCHpass</h3>
            <p>A simple app to share secrets</p>
          </div>
        </section>
      </div>

      <h4 id="success">
        {error
          ? "The secret has expired or has already been recovered. Please contact the sender of the secret."
          : "Your recovered secret is:"}
      </h4>

      {!error && (
        <>
          <div className="input-group" id="input-group">
            <span className="input-group-addon" id="basic-addon1">
              <span
                className="glyphicon glyphicon-lock"
                aria-hidden="true"
              ></span>
            </span>
            <textarea
              rows="10"
              cols="50"
              id="password1"
              className="form-control"
              aria-describedby="basic-addon1"
              readOnly
              autoComplete="off"
              style={{
                WebkitBoxSizing: "border-box",
                MozBoxSizing: "border-box",
                boxSizing: "border-box",
              }}
              required
              value={secret}
            >
              {secret}
            </textarea>
          </div>
          <span className="input-group-button" id="expiration">
            <button
              onClick={() => clickCopy(secret)}
              title="Copy to clipboard"
              className="btn btn-primary"
            >
              <i className="fa fa-clipboard" />
              Copy
            </button>
          </span>
        </>
      )}
    </>
  );
};

export default SecretPage;
