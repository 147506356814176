// If the shell environment variable gets set, it will override the .env files defined.
const url = process.env.REACT_APP_API;

const encodeSecret = async (secret, ttl) => {
    const data = { secret: secret, ttl: ttl }
    const options = {
        method: 'POST',
        body: JSON.stringify(data)
    };

    return await fetch(url, options);
};

const decodeSecret = async (uid, key) => {
    const options = {
        method: 'GET'
    };

    return await fetch(`${url}/${uid}/${key}`, options);
};

export { encodeSecret, decodeSecret };
