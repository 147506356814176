import React from 'react';
import KochLogo from '../images/koch_logo.png';

const NavBar = () => (
    <nav className="navbar navbar-default">
        <div className="container-fluid">
            <div className="navbar-header">
                <a className="navbar-left hidden-xs" href="#/">
                    <img src={KochLogo} style={{ width: "108pt" }} alt="Koch Logo" />
                </a>
            </div>
            <ul className="nav navbar-nav navbar-right hidden-xs">
                <li>
                    <a href="#/">
                        <i className="fa fa-home" /> Home
                    </a>
                </li>
                <li>
                    <a href="#/about">
                        <i className="fa fa-shield" /> About
                    </a>
                </li>
            </ul>
        </div>
    </nav>
);

export default NavBar;