import React from 'react';

const NotFoundPage = () => (
  <div className="jumbotron text-center" id="jumbo">
    <section>
      <div>
        <h1>404</h1>
        <h2>Page Not Found</h2>
      </div>
    </section>
  </div>
);


export default NotFoundPage;
