import React from 'react';

const AboutPage = () => (
  <>
    <div className="jumbotron text-center" id="jumbo">
      <section>
        <div className="page-header" id="page-header">
          <h3>KOCHpass</h3>
          <p>A simple app to share secrets</p>
        </div>
      </section>
    </div>
    <div className="about-header" id="about-header">
      <h4>KOCHpass allows users to share secrets in a secure and ephemeral way</h4>
      <p>Sharing one-time URLs is much safer than sending your sensitive data in emails, texts or instant message</p>
      <p>Secrets are always encrypted and decryption keys are never stored on KOCHpass</p>
      <p>Data stored on KOCHpass should be considered ephemeral at all times</p>
      <br />
      <h4>How do I use KOCHpass?</h4>
      <p>Input your secret, select the expiration of the secret and click "Generate" to create a one-time use URL for secret retrieval</p>
      <p>Share this URL with your intended recipient</p>
      <p>Secrets are immediately purged upon retrieval or expiration, whichever happens first</p>
      <br />
      <h4>Who can retrieve my secret?</h4>
      <p>Anyone within possession of the URL can retrieve your secret. First come first serve</p>
      <br />
      <h4>Is there any way to retrieve the secret apart from the URL?</h4>
      <p>Decrypting secrets outside of the one-time URL process is not possible</p>
      <p>Keys are contained only within the URL and stored no where else</p>
    </div>
  </>
);

export default AboutPage;

