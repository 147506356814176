import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { encodeSecret } from '../services/SecretService'

const HomePage = ({ toggleSpinner, setSecretInfo }) => {

	const history = useHistory();

	const ttls = [
		{ name: "1 Hour", value: "3600" },
		{ name: "1 Day", value: "86400" },
		{ name: "1 Week", value: "604800" }
	];
	const [password, setPassword] = useState('');
	const [selectedItemIndex, setSelectedItemIndex] = useState(1);

	const onSubmit = async (event) => {
		event.preventDefault();

		if (password) {
			toggleSpinner(true);

			// parseInt() is used here to prevent a security vulnerability called "generic object injection sink"
			const ttlIndex = parseInt(selectedItemIndex)
			const ttl = ttls[ttlIndex].value;
			const response = await encodeSecret(password, ttl);
			setSecretInfo(await response.json());
			toggleSpinner(false);
			if (response.ok) {
				history.push("/share");
			}
		}
	};

	return (
		<>
			<div className="jumbotron text-center" id="jumbo">
				<section>
					<div className="page-header" id="page-header">
						<h3>KOCHpass</h3>
						<p>A simple app to share secrets</p>
					</div>
				</section>
			</div>
			<form id="password-create" name="password-create" onSubmit={onSubmit} >
				<div className="input-group" id="input-group">
					<span className="input-group-addon" id="basic-addon1"><span className="glyphicon glyphicon-lock" aria-hidden="true"></span></span>
					<textarea rows="10" cols="50" id="password" ng-model="password" name="password" className="form-control" placeholder="This tool allows you to share secrets in a secure, ephemeral way. Input a single or multi-line secret, its expiration time, and click Generate URL. Share the one-time use URL with your intended recipient."
						aria-describedby="basic-addon1" autoComplete="off" value={password} onChange={(e) => setPassword(e.target.value)}
						style={{ WebkitBoxSizing: "border-box", MozBoxSizing: "border-box", boxSizing: "border-box" }}
						required></textarea>
				</div>
				<div className="input-group" id="expiration">
					<span className="input-group-addon">Expiration</span>
					<select className="form-control" onChange={(e) => setSelectedItemIndex(e.target.selectedIndex)} id="ttl" data-toggle="tooltip"
						title="Please select an expiration for your secret" required defaultValue={ttls[1].value}>
						{
							ttls.map(ttl => <option value={ttl.value} key={ttl.name} >{ttl.name}</option>)
						}
					</select>
					<span className="input-group-btn">
						<button type="submit" id="submit" className="btn btn-primary" style={{ "boxShadow": "2px 2px 2px #888888" }}>
							Generate URL
						</button>
					</span>
				</div>
			</form>
		</>
	);
}

export default HomePage;
