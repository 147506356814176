const clickCopy = (toCopy) => {
    try {
        document.querySelector('.form-control').select();
        const successful = document.execCommand('copy');
        if (!successful) throw new Error();
    } catch (err) {
        window.prompt("Copy to clipboard: Ctrl+C, Enter", toCopy);
    }
};

export { clickCopy };