import React, { useState } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import './css/custom.css'
import './css/fonts.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css'

import AboutPage from './pages/AboutPage.js';
import HomePage from './pages/HomePage.js';
import NotFoundPage from './pages/NotFoundPage.js';
import SecretPage from './pages/SecretPage.js';
import SharePage from './pages/SharePage.js';

import NavBar from './components/NavBar.js';


const App = () => {

  const [secretInfo, setSecretInfo] = useState({});

  const toggleSpinner = (active) => {
    document.querySelector('body').className = active ? 'loading' : '';
  };

  return (
    <>
      <NavBar />
      <div className="container" id="container">
        <div className="row">
          <div className="col-sm-10">
            <Router basename="/#">
              <Switch>
                <Route exact path="/" render={() => <HomePage toggleSpinner={toggleSpinner} setSecretInfo={setSecretInfo} />} />
                <Route exact path="/about" component={AboutPage} />
                <Route exact path="/share" render={() => <SharePage url={secretInfo.url} />} />
                <Route path="/:uid/:key" render={() => <SecretPage toggleSpinner={toggleSpinner} />} />
                <Route component={NotFoundPage} />
              </Switch>
            </Router>
          </div>
        </div>
        <div className="modal" id="spinner"></div>
      </div>
    </>
  );
}

export default App;
